import { useState, useEffect } from 'react';
import axios from 'axios';
import { addUserProdotto, addUserProdottoMultipli } from '../../api/prodotti/api';
import Multiselect from 'multiselect-react-dropdown';
import './css/Aggiungi.css';
import { MapContainer, TileLayer, Marker, Popup,useMapEvents } from 'react-leaflet';
import L from 'leaflet';

const idCliente = 1;

const MapClickHandler = ({setCordinatesFromClick}) => {
    const [position, setPosition] = useState(null);
  
    const map = useMapEvents({
        click(e) {
            const { lat, lng } = e.latlng;
            console.log(e)
            //setPosition({ lat, lng });
            setCordinatesFromClick(lat, lng);
            //alert(`Hai cliccato sulla mappa alle coordinate: ${lat}, ${lng}`);
        },
    });
  
    return position === null ? null : (
        <div>
            <p>Coordinate: {position.lat}, {position.lng}</p>
        </div>
    );
  };

const Aggiungi = ({ option }) => {
    const [posizione, setPosizione] = useState('');
    const [latitudine, setLatitudine] = useState('43.5565103750476');
    const [longitudine, setLongitudine] = useState('12.40113755208459');
    const [numero, setNumero] = useState('');
    const [idProdotto, setIdProdotto] = useState('');
    const [statoSelected, setStatoSelected] = useState('');
    const [prezzo, setPrezzo] = useState('');
    const [impiego, setImpiego] = useState('');
    const [optionImpiego, setOptionImpiego] = useState({
        options: [{ name: 'eventi', id: 1, prezzo: '' }, { name: 'cantiere', id: 2, prezzo: '' }]
    });
    const [address, setAddress] = useState('');
    const [debouncedAdress, setDebouncedAdress] = useState('');
    const [city, setCity] = useState('');

    const [file, setFile] = useState(null);
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState('');

    const [fromMapFalse, setFromMapFalse] = useState(false);


    // Icona custom per React-Leaflet (corregge il problema delle icone di default)
    const customIcon = new L.Icon({
        iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
    });

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedAdress(address);
        }, 500);

        return () => clearTimeout(handler);
    }, [address]);

    useEffect(() => {
        if (debouncedAdress) {
            if(!fromMapFalse){
            getCoordinates(debouncedAdress);
        }
        }
    }, [debouncedAdress]);

    const handleAdressChange = (e) => {
        setFromMapFalse(false);
        setAddress(e.target.value);
    };



    const getCoordinates = async (address) => {
        try {
            const response = await axios.get('https://nominatim.openstreetmap.org/search', {
                params: {
                    format: 'json',
                    q: address
                }
            });

            const data = response.data;
            if (data.length > 0) {
                setLatitudine(data[0].lat);
                setLongitudine(data[0].lon);
                setCity(data[0].name);
            } else {
                console.log('Nessun risultato trovato');
            }
        } catch (error) {
            console.error('Errore durante la richiesta:', error);
        }
    };


    const getCity = async (lat, lon) => {
        try {//https://nominatim.openstreetmap.org/reverse?lat=${coord[0]}&lon=${coord[1]}&format=json
          const response = await axios.get(
            `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&format=json&appid=${process.env.REACT_APP_OPENWEATHERMAP_API_KEY}`
          );
          return response?.data?.address || "Unknown location"; // Ritorno il nome della città
        } catch (error) {
          setError(true);
          setErrorText(error.message);
          return "Unknown location"; // In caso di errore, ritorno un valore di fallback
        }
      };
    const onSelect = (selectedList, selectedItem) => setImpiego(selectedList);
    const onRemove = (selectedList, removedItem) => setImpiego(selectedList);
    const aggiungi = async () => {
            let disponibile = statoSelected == 'si' ? true : false
            //const {tipologia,impiego,prezzo,posizione,numero,disponibile}
            let prezzoIfNull = impiego.map((impieg) => {
                if (impieg.prezzo == '') {
                    impieg.prezzo = 0
                }
                return impieg
            })
            
            if (numero > 1) {
                // se il numero è maggiore di 1 chiamo l'endo point aggiungi multipli 
                
                try {

                    if (option == 'bagni') {
                        let prodottoAggiunto = await addUserProdottoMultipli(option,prezzoIfNull,prezzo,latitudine,longitudine,disponibile,idProdotto,numero,city)
                        setLatitudine('')
                        setLongitudine('')
                        setNumero('')
                        setIdProdotto('')
                        setStatoSelected('')
                        setPrezzo('')
                        setImpiego('')
                        setOptionImpiego({options: [{name: 'eventi', id: 1, prezzo:''},{name: 'cantiere', id: 2, prezzo:''}]})
                        setCity('')
                        console.log(prodottoAggiunto)
                    }
                }
                catch (error) {
                    setError(true)
                    setErrorText(error.message)
                }
                return
            }
            try {
                if(option == 'bagni') {
                    let prodottoAggiunto = await addUserProdotto(option,impiego,prezzo,latitudine,longitudine,disponibile,idProdotto,city)
                    setLatitudine('')
                    setLongitudine('')
                    setNumero('')
                    setIdProdotto('')
                    setStatoSelected('')
                    setPrezzo('')
                    setImpiego('')
                    setOptionImpiego({options: [{name: 'eventi', id: 1, prezzo:''},{name: 'cantiere', id: 2, prezzo:''}]})
                    setCity('')


                    console.log(prodottoAggiunto)
                }
            } catch (error) {
                setError(true)
                setErrorText(error.message)
            }
        }


        // Funzione per gestire il caricamento del file
        const caricaCsv = (event) => {
            setFile(event.target.files[0]);
        };

    // Funzione per inviare il file al server
    const aggiungiAutoDaCsv = async () => {
        if (!file) {
        alert('Seleziona un file CSV prima di continuare');
        return;
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
        const response = await axios.post('https://localhost:5000/upload', formData, {
            headers: {
            'Content-Type': 'multipart/form-data',
            },
        });
        alert('File inviato con successo!');
        console.log('Risposta del server:', response.data);
        } catch (error) {
        console.error('Errore durante l\'invio del file:', error);
        alert('Errore durante l\'invio del file');
        }
    };


    const setCordinatesFromClick = async (lat, lng) => {
        setLatitudine(lat);
        setLongitudine(lng);

        let city = await getCity(lat, lng);
        let tmpAddress = '';

        if(city?.town){
            tmpAddress += city.town;
        }
        if(city?.road){
            tmpAddress = city.road + ', ' + tmpAddress;
        }
        if(city.length){
            tmpAddress = `${lat}, ${lng}`;
        }
        setFromMapFalse(true);
        setAddress(tmpAddress);
    }

    return (
        <div className="aggiungi">
            <p>Aggiungi {option} manualmente</p>
            <div className="container-aggiungi-servizio">
                <label>Inserisci la via con il formato: via, numero civico, città</label>
                <input
                    type="text"
                    value={address}
                    onChange={handleAdressChange}
                    placeholder="Inserisci via, numero, città..."
                />

                    <MapContainer
                        center={[latitudine, longitudine]}
                        zoom={13}
                        style={{ width: '100%', height: '400px', marginTop: '10px' }}
                    >
                        <MapClickHandler setCordinatesFromClick={setCordinatesFromClick}/>
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        />
                        <Marker position={[latitudine, longitudine]} icon={customIcon}>
                            <Popup>
                                Latitudine: {latitudine} <br /> Longitudine: {longitudine}
                            </Popup>
                        </Marker>
                    </MapContainer>
               

                <input
                    type="text"
                    placeholder="Latitudine"
                    value={latitudine}
                    onChange={(e) => setLatitudine(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Longitudine"
                    value={longitudine}
                    onChange={(e) => setLongitudine(e.target.value)}
                />
                <input
                    type="number"
                    placeholder="Numero"
                    value={numero}
                    onChange={(e) => setNumero(e.target.value)}
                />
                <select
                    className="select-aggiungi-stato"
                    value={statoSelected}
                    onChange={(e) => setStatoSelected(e.target.value)}
                >
                    <option value="">Seleziona lo stato</option>
                    <option value="si">Disponibile</option>
                    <option value="no">Non disponibile</option>
                </select>

                <Multiselect
                    options={optionImpiego.options}
                    selectedValues={impiego}
                    onSelect={onSelect}
                    onRemove={onRemove}
                    displayValue="name"
                />

                {impiego && impiego?.map((impieg, index) => (
                    <input
                        key={index}
                        type="number"
                        placeholder={`Prezzo ${impieg.name}`}
                        value={impieg.prezzo}
                        onChange={(e) => {
                            let newArray = [...impiego];
                            newArray[index].prezzo = e.target.value;
                            setImpiego(newArray);
                        }}
                    />
                ))}

            <button onClick={aggiungi}>Aggiungi</button>
            </div>
        </div>
    );
};

export default Aggiungi;
