import { useState,useEffect } from 'react'
import * as XLSX from "xlsx";
import axios from 'axios'
import { getBagniNearby, prenotaBagno } from '../../api/prodotti/api'
import Multiselect from 'multiselect-react-dropdown';
import './css/Aggiungi.css'
import Calendario from './Calendario';

import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { MapContainer, TileLayer, Marker, Popup,useMapEvents } from 'react-leaflet';
import L from 'leaflet';


const statoConsegna = ['daconsegnare','consegnato','annullato','inattivo','ritirato']
const statoPagamento = ['pagato','non pagato']
const idCliente = 1
const customIcon = new L.Icon({
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});
const MapClickHandler = ({setCordinatesFromClick}) => {
  const [position, setPosition] = useState(null);

  const map = useMapEvents({
      click(e) {
          const { lat, lng } = e.latlng;
          console.log(e)
          //setPosition({ lat, lng });
          setCordinatesFromClick(lat, lng);
          //alert(`Hai cliccato sulla mappa alle coordinate: ${lat}, ${lng}`);
      },
  });

  return position === null ? null : (
      <div>
          <p>Coordinate: {position.lat}, {position.lng}</p>
      </div>
  );
};

const Aggiungi = ({option}) => {
    const [latitudine, setLatitudine] = useState('43.5565103750476')
    const [longitudine, setLongitudine] = useState('12.40113755208459')
    const [numero, setNumero] = useState('')
    const [idProdotto, setIdProdotto] = useState('')
    const [statoSelected, setStatoSelected] = useState('')
    const [prezzo, setPrezzo] = useState('')
    const [impiego, setImpiego] = useState('')
    const [isAttivoPulizia, setIsAttivoPulizia] = useState(true)
    const [periodicita, setPeriodicita] = useState('')
    const [ricorrenza, setRicorrenza] = useState('')
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [timeOptions, setTimeOptions] = useState([]);
    const [nome, setNome] = useState('')
    const [selectStatoConsegna, setSelectStatoConsegna] = useState('')
    const [selectStatoPagamento, setSelectStatoPagamento] = useState('')
    const [emailCliente, setEmailCliente] = useState('')


    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [errorText, setErrorText] = useState('')
    const [file, setFile] = useState(null);
    const [optionImpiego, setOptionImpiego] = useState({options: [{name: 'eventi', id: 1, prezzo:''},{name: 'cantiere', id: 2, prezzo:''}]})
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    const [bagniDisponibili, setBagniDisponibili] = useState({options:[]})
    const [bagniSelezionati, setBagniSelezionati] = useState([])


    const [address, setAddress] = useState('')
    const [debouncedAdress, setDebouncedAdress] = useState('')
    const [googleMapsUrl, setGoogleMapsUrl] = useState('')


    const [loadersOn, setLoadersOn] = useState(false);
    const [loderExcel, setLoaderExcel] = useState(false);
    const [showAllert, setShowAllert] = useState(false);
    const [showAllertText, setShowAllertText] = useState('')

    const [fromMapFalse, setFromMapFalse] = useState(false);
    
    // file excel
    const [righeFileExcel, setRigheFileExcel] = useState([])
    const [righeElaborate, setRigheElaborate] = useState(0)
    const [righeFallite, setRigheFallite] = useState(0)

    useEffect(() => {

    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
        timeOptions.push(time);
      }
    }
    setTimeOptions(timeOptions);

    }, [])

    const handleStartTimeChange = (event) => {
      setStartTime(event.target.value);
    };
  
    const handleEndTimeChange = (event) => {
      setEndTime(event.target.value);
    };


    const aggiungi = async (isExcel, data1,data2,data3,data4,data5,data6,data7,data8,data9,data10,data11,data12,data13,data14,data15,data16) => {

      setLoadersOn(true)


      let bagniId = []
      bagniSelezionati.map((b) => {
        bagniId.push(b.id)
      })


        let distanza = 10
            // se il numero è maggiore di 1 chiamo l'endo point aggiungi multipli 
            
          try {

              if (option == 'bagni') {
                if (isExcel){
                  const response = await prenotaBagno(
                    data1,
                    data2,
                    data3,
                    data4,
                    data5,
                    data6,
                    data7,
                    data8,
                    data9,
                    data10,
                    data11,
                    data12,
                    data13,
                    data14,
                    data15,
                    data16
                  )
                  if(response.message){
                    setShowAllertText(response.message)
                    setShowAllert(true)
                    setLoadersOn(false)
                    // scorri la pagina in alto
                    window.scrollTo(0, 0)
                    return response.message
                  }
                }else{
                  const response = await prenotaBagno(    
                    bagniId, 
                    startDate, 
                    endDate,
                    nome,
                    longitudine,
                    latitudine,
                    isAttivoPulizia,
                    startTime,
                    endTime,
                    periodicita,
                    ricorrenza,
                    selectStatoConsegna,
                    selectStatoPagamento,
                    option,
                    impiego,
                    emailCliente
                    )
                    if(response.message){
                      setShowAllertText(response.message)
                      setShowAllert(true)
                      setLoadersOn(false)
                      // scorri la pagina in alto

                      window.scrollTo(0, 0)
                    }
                  setLatitudine('')
                  setLongitudine('')
                  setNumero('')
                  setIdProdotto('')
                  setStatoSelected('')
                  setPrezzo('')
                  setImpiego('')
                  setOptionImpiego({options: [{name: 'eventi', id: 1, prezzo:''},{name: 'cantiere', id: 2, prezzo:''}]})
                  setIsAttivoPulizia(false)
                  setPeriodicita('')
                  setRicorrenza('')
                  setStartTime('')
                  setEndTime('')
                  setNome('')
                  setSelectStatoConsegna('')
                  setSelectStatoPagamento('')
                  setBagniSelezionati([])
                  setEmailCliente('')
                  setAddress('')

                  setBagniDisponibili({options:[]})


                }

              }
          }
          catch (error) {
              setError(true)
              setLoadersOn(false)
              setErrorText(error.message)
          }
          return
        
    }
    const handleFileUpload = async (event) => {
      setLoaderExcel(true)
      const file = event.target.files[0];
      if (!file) return;
    
      const reader = new FileReader();
    
      // Legge il file come Promise
      const readFile = (file) => {
        return new Promise((resolve, reject) => {
          reader.onload = (e) => resolve(e.target.result);
          reader.onerror = (error) => reject(error);
          reader.readAsBinaryString(file);
        });
      };
    
      try {
        const binaryStr = await readFile(file);
        const workbook = XLSX.read(binaryStr, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet);
    
        let falliti = []; // Array per raccogliere i dati delle righe fallite

        
        // Esegue tutte le chiamate asincrone in parallelo
        console.log(parsedData.length)
        setRigheFileExcel(parsedData.length)
    
        let righeElaborate = 0
        let righeFallite = 0  
        for (const data of parsedData) {
          righeElaborate++
          setRigheElaborate(righeElaborate)

          try {
            let adr = `${data.indirizzo}, ${data.comune}`;
            let coordinate = await getCoordinates(adr, true);



            const excelEpoch = new Date(1900, 0, 1);

            // Aggiungo i giorni, sottraendo 2 per correggere il bug di Excel sul 29 febbraio 1900
            const jsDate = data.start ? new Date(excelEpoch.getTime() + (data.start - 2) * 24 * 60 * 60 * 1000) : null;

            let dateStartToPass = data.start ? jsDate : startDate
    
            const bagniNear = await getBagniNear(
              true,
              option,
              data.impiego,
              coordinate.lat,
              coordinate.lon,
              100,
              dateStartToPass,
              endDate,
              data.n_bagni,
              data.prezzo || 0
            );
    
            console.log("Bagni vicini:", bagniNear);
    
            let prenotazione = await aggiungi(
              true,
              bagniNear.map((b) => b.id),
              dateStartToPass,
              endDate,
              data.cliente,
              coordinate.lon,
              coordinate.lat,
              data.tipo_cod ? true : false,
              dateStartToPass,
              endDate,
              data.tipo_cod == "Q" ? "2m" : "1s",
              data.tipo_cod == "Q" ? "2m" : "1s",
              data.stato,
              data.statoP,
              option,
              data.impiego,
              data.email
            );
    
            console.log("Prenotazione effettuata:", prenotazione);
    
            // Se la prenotazione fallisce, salviamo i dati nel file di errore
            if (prenotazione !== "Prenotazioni effettuate con successo") {
              righeFallite++
              setRigheFallite(righeFallite)
              falliti.push(data);
            }
    
          } catch (error) {
            console.error(`Errore durante la gestione della riga ${JSON.stringify(data)}:`, error);
            falliti.push(data); // Salva anche i fallimenti dovuti a errori
            righeFallite++
            setRigheFallite(righeFallite)
          }
        }
        
        // Se ci sono errori, crea il file Excel con le righe fallite
        if (falliti.length > 0) {
          const ws = XLSX.utils.json_to_sheet(falliti);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "FALLITI");
    
          // Converti il file Excel in Blob
          const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
          const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    
          // Scarica il file
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "falliti.xlsx");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
    
      } catch (error) {
        console.error("Errore nella lettura del file:", error);
      }
      setLoaderExcel(false)
    };
    
    
    /*const handleFileUpload = async (event) => {
      const file = event.target.files[0];
      if (!file) return;
    
      const reader = new FileReader();
    
      const readFile = (file) => {
        return new Promise((resolve, reject) => {
          reader.onload = (e) => resolve(e.target.result);
          reader.onerror = (error) => reject(error);
          reader.readAsBinaryString(file);
        });
      };
    
      try {
        const binaryStr = await readFile(file);
        const workbook = XLSX.read(binaryStr, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet);
        // Esegue tutte le chiamate asincrone in parallelo
        await Promise.all(parsedData.map((data) => getCoordinates(`${data.indirizzo}, ${data.comune}`)));
      } catch (error) {
        console.error("Error reading file:", error);
      }
    };*/

    // Funzione per gestire il caricamento del file
    const caricaCsv = (event) => {
        setFile(event.target.files[0]);
    };

  // Funzione per inviare il file al server
  const aggiungiAutoDaCsv = async () => {
    if (!file) {
      alert('Seleziona un file CSV prima di continuare');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post('https://localhost:5000/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('File inviato con successo!');

    } catch (error) {
      console.error('Errore durante l\'invio del file:', error);
      alert('Errore durante l\'invio del file');
    }
  };


    const onSelect = (selectedList, selectedItem) => {

      setBagniSelezionati(selectedList)
    }

    const onRemove = (selectedList, removedItem) => {
      setBagniSelezionati(selectedList)
    }


    useEffect(() => {
        console.log(impiego)
    },[impiego])


    

    const getData = (startDate,endDate) => {
        if (startDate) {
            setStartDate(startDate)
        }
        if (endDate) {
            setEndDate(endDate)
        }
    }

    const getCity = async (lat, lon) => {
      try {//https://nominatim.openstreetmap.org/reverse?lat=${coord[0]}&lon=${coord[1]}&format=json
        const response = await axios.get(
          `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&format=json&appid=${process.env.REACT_APP_OPENWEATHERMAP_API_KEY}`
        );
        return response?.data?.address || "Unknown location"; // Ritorno il nome della città
      } catch (error) {
        setError(true);
        setErrorText(error.message);
        return "Unknown location"; // In caso di errore, ritorno un valore di fallback
      }
    };
    
    const getBagniNear = async (isExcel,data1,data2, data3,data4, data5, data6, data7, data8,data9) => {
      const distanza = 200;

      try {
        let prodottiLiberi = ''
        if(isExcel){
          prodottiLiberi = await getBagniNearby(data1, data2, data3, data4, data5, data6, data7, data8, data9);
        }else{
          prodottiLiberi = await getBagniNearby(option, impiego, latitudine, longitudine, distanza, startDate, endDate, numero, prezzo);
        }
        const tmpProdotti = [];


        if (numero) {
          // Prendi i primi numero + 4 prodotti
          let nBagni  =  parseInt(numero) + 4
          const primiNProdotti = prodottiLiberi.slice(0, nBagni);
        
          try {
            const richieste = primiNProdotti.map(async (prodotto) => {
              const coordinates = [
                [longitudine, latitudine], // Punto di partenza
                [prodotto.longitudine, prodotto.latitudine], // Destinazione
              ];
          
              // Effettua la richiesta per ogni prodotto
              const response = await axios.post(
                'https://api.openrouteservice.org/v2/directions/driving-car',
                { coordinates },
                {
                  headers: {
                    'Accept': 'application/json, application/geo+json, application/gpx+xml, img/png; charset=utf-8',
                    'Authorization': process.env.REACT_APP_OPENROUTESERVICE_API_KEY,
                    'Content-Type': 'application/json; charset=utf-8',
                  },
                }
              );
          
              // Estrai il tempo di percorrenza
              const tempoPercorrenza = Math.ceil(response.data.routes[0].summary.duration /60); // Tempo in secondi
              return { ...prodotto, tempoPercorrenza }; // Aggiungi il tempo di percorrenza al prodotto
            });
          
            // Aspetta tutte le richieste
            const risultatiConTempi = await Promise.all(richieste);
          
            // Ordina i prodotti in base al tempo di percorrenza
            const ordinatiPerTempo = risultatiConTempi.sort((a, b) => a.tempoPercorrenza - b.tempoPercorrenza);
          
            // Aggiorna `prodottiLiberi` mantenendo il resto invariato
            prodottiLiberi = [
              ...ordinatiPerTempo, // Primi numero + 4 ordinati
              ...prodottiLiberi.slice(numero + 4), // Il resto non ordinato
            ];
          

          } catch (error) {
            console.error('Errore durante il calcolo dei tempi di percorrenza:', error);
            setLoadersOn(false);
          }
          
        }
        
        // Usa `for...of` per gestire funzioni asincrone nel loop
        for (const prodotto of prodottiLiberi) {
          const posizione = await getCity(prodotto.latitudine, prodotto.longitudine);
          tmpProdotti.push({name: `bagno: ${prodotto.numero} | posizione ${posizione.town} | tempo ${(prodotto.tempoPercorrenza === 0 || prodotto.tempoPercorrenza > 0) ?prodotto.tempoPercorrenza: 'non disponibile'}`, id: prodotto._id, posizione });
        }
        

    
        if (isExcel) {
          let number = parseInt(data8) - 1;
          console.log(number, 'this is a number');
          console.log(tmpProdotti.slice(0, number + 1), 'this is tmpProdotti');
          return tmpProdotti.slice(0, number + 1);  // Prende tutti gli elementi da 0 a number
      }
        setBagniDisponibili({ options: tmpProdotti });
        setLoadersOn(false);
      } catch (error) {
        setError(true);
        setErrorText(error.message);
        setLoadersOn(false);
      }
    };

    useEffect(() => {
        if (startDate && endDate && latitudine && longitudine && numero) {
          // Effettua la chiamata API solo se tutti i campi sono valorizzati
          getBagniNear();
        }
      }, [startDate, endDate, latitudine, longitudine, numero,impiego]);




      useEffect(() => {
        const handler = setTimeout(() => {
          setDebouncedAdress(address);
        }, 500); // 500ms di ritardo
    
        // Pulisce il timeout precedente se l'inputValue cambia prima che scada il tempo
        return () => {
          clearTimeout(handler);
        };
      }, [address]);


      const handleAdressChange = (e) => {
        setAddress(e.target.value);
      };

      async function getCoordinates(address,isFromExcel) {
        try {
            const response = await axios.get('https://nominatim.openstreetmap.org/search', {
                params: {
                    format: 'json',
                    q: address
                }
            });
            
            const data = response.data;
            if (data.length > 0) {
              if(isFromExcel){
                return {lat: data[0].lat, lon: data[0].lon}
              }
                setLatitudine(data[0].lat)
                setLongitudine(data[0].lon)
                setGoogleMapsUrl(`https://www.google.com/maps?q=${data[0].lat},${data[0].lon}&z=15&output=embed`)
            } else {
                console.log('Nessun risultato trovato');
            }
        } catch (error) {
            console.error('Errore durante la richiesta:', error);
        }
    }

      useEffect(() => {
        if (debouncedAdress) {
          if(!fromMapFalse){
            getCoordinates(debouncedAdress,false);
          }
        }
        }, [debouncedAdress]);
        

        const setCordinatesFromClick = async (lat, lng) => {
          setLatitudine(lat);
          setLongitudine(lng);
  
          let city = await getCity(lat, lng);
          let tmpAddress = '';
  
          if(city?.town){
              tmpAddress += city.town;
          }
          if(city?.road){
              tmpAddress = city.road + ', ' + tmpAddress;
          }
          if(city.length || !city.road){
              tmpAddress = `${lat}, ${lng}`;
          }
          setFromMapFalse(true);
          setAddress(tmpAddress);
      }

      const scaricaTemplateExcel = () => {
        // prendo il file excel dalla cartella template
        const fileUrl = "/template.xlsx"; 
        // creo un link per il download
        const link = document.createElement("a");
        link.href = fileUrl;
        link.setAttribute("download", "template.xlsx");
      
        // Aggiungi il link al DOM, attiva il click, poi rimuovilo
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      
    return (
        <div className='aggiungi'>
        {loadersOn || loderExcel ?  <>
          <div className="loader-container">
            <div>
              <h2>Caricamento in corso...</h2>
              </div>
              <div>
              <Spinner animation="grow" variant="primary" />
              <div>
              {loderExcel && <>
              <p>righe elaborate: {righeElaborate}</p>
              <p>righe fallite: {righeFallite}</p>
              <p>righe totali: {righeFileExcel}</p>
              </>}
              </div>
              
              </div>
              </div> </> 
              :<> 
            <p>Prenota {option} manualmente</p>
            <div className = 'container-aggiungi-servizio'>
            <Alert show={showAllert} variant="dark">
                <Alert.Heading>Alert</Alert.Heading>
                <p>
                  {showAllertText}
                </p>
                <hr />
                <div className="d-flex justify-content-end">
                  <Button onClick={() => setShowAllert(false)} variant="outline-success">
                    Chiudi
                  </Button>
                </div>
              </Alert>
                <Calendario getData={getData} prenotazione={true} showDateSemplificate={false}/>
                <label> inserisci la via con il formato: via, numero civico, città</label>
            <input
                    type="text"
                    value={address}
                    onChange={handleAdressChange}
                    placeholder="inserisci via, numero, citta..."
                />
                {/* googleMapsUrl ? 
                <iframe
                  title="Google Maps"
                  src={googleMapsUrl}
                  
                  width={window.innerWidth < 500 ? "300" : "500"}
                  height={window.innerWidth < 500 ? "250" : "500"}
                  allowFullScreen=""
                  loading="lazy"
                  style={{ border: 0 }}
                ></iframe> : null*/}
                  <MapContainer
                        center={[latitudine, longitudine]}
                        zoom={13}
                        style={{ width: '100%', height: '400px', marginTop: '10px' }}
                    >
                        <MapClickHandler setCordinatesFromClick={setCordinatesFromClick}/>
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        />
                        <Marker position={[latitudine, longitudine]} icon={customIcon}>
                            <Popup>
                                Latitudine: {latitudine} <br /> Longitudine: {longitudine}
                            </Popup>
                        </Marker>
                    </MapContainer>
                <input type="text" placeholder='latitudine' value={latitudine} onChange={(e) => setLatitudine(e.target.value)} />
                <input type="text" placeholder='longitudine' value={longitudine} onChange={(e) => setLongitudine(e.target.value)} />
                <input type='number' placeholder='numero' value={numero} onChange={(e) => setNumero(e.target.value)} />
                <select className='select-aggiungi-impiego' value={impiego} onChange={(e) => setImpiego(e.target.value)}>
                    <option value=''>Seleziona l'impiego cantiere...</option>
                    <option value='eventi'>Eventi</option>
                    <option value='cantiere'>Cantiere</option>
                 </select>
                {
                bagniDisponibili.options.length ? 
                <>
                <label>Seleziona <b style={{color:'red',fontSize:'18px'}}>{numero == bagniSelezionati.length ? '': numero - bagniSelezionati.length }</b></label>
                <Multiselect
                    options={bagniDisponibili.options} // Options to display in the dropdown
                    selectedValues={bagniSelezionati} // Preselected value to persist in dropdown
                    onSelect={onSelect} // Function will trigger on select event
                    onRemove={onRemove} // Function will trigger on remove event
                    displayValue="name" // Property name to display in the dropdown options
                /></> : numero && impiego ?
                <div style={{displey:'flex',flexDirection:'column-reverse'}} >
                  <span>caricamento bagni disponibili</span>
                  <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div> : null
                }
                <label>Associa servizio pulizia: <b style={{color:'red',fontSize:'18px'}}>{isAttivoPulizia ? 'si':'no'}</b></label>
                < input type = "checkbox" checked = {isAttivoPulizia} onChange = {(e)=>setIsAttivoPulizia(e.target.checked)} />
                {isAttivoPulizia ? 
                <>
                  <label>Seleziona ricorrenza <b style={{color:'red',fontSize:'18px'}}>{isAttivoPulizia ? 'seleziona una ricorrenza per le pulizie':''}</b></label>
                  <select className='select-aggiungi-impiego' value={ricorrenza} onChange={(e) => setRicorrenza(e.target.value)}>
                    <option value=''>Seleziona la ricorrenza...</option>
                    '1s','1m','2m','3m', '1g', '2g', '3g', '4g
                    <option value='1s'>una a settimana</option>
                    <option value='1m'>1 al mese</option>
                    <option value='2m'>2 al mese</option>
                    <option value='3m'>3 al mese</option>
                    <option value='1g'>1 al gorno</option>
                    <option value='2g'>2 al giorno</option>
                    <option value='3g'>3 al giorno</option>
                    <option value='4g'>4 al giorno</option>
                  </select>


                {startTime && endTime && (
                  <p>
                    Intervallo selezionato: {startTime} - {endTime}
                  </p>
                )}
                </> : null}
                <label>Nome prenotazione</label>
                <input type='text' placeholder='...esempio srl' value={nome} onChange={(e) => setNome(e.target.value)} />
                <label>email cliente</label>
                <input type='email' placeholder='srl@gmail.com' required value={emailCliente} onChange={(e) => setEmailCliente(e.target.value)} />
                <label>Selezioa stato consegna</label>
                <select className='select-aggiungi-impiego' value={selectStatoConsegna} onChange={(e) => setSelectStatoConsegna(e.target.value)}>
                    <option value=''>Seleziona lo stato della consegna...</option>
                    {statoConsegna.map((stato) => {
                        return <option value={stato}>{stato}</option>
                    })}
                </select>
                <label>Seleziona stato pagamento</label>
                <select className='select-aggiungi-impiego' value={selectStatoPagamento} onChange={(e) => setSelectStatoPagamento(e.target.value)}>
                    <option value=''>Seleziona lo stato del pagamento...</option>
                    {statoPagamento.map((stato) => {
                        return <option value={stato}>{stato}</option>
                    })}
                </select>
                { (bagniSelezionati.length == numero)
                 && 
                 ((isAttivoPulizia && ricorrenza) || (!isAttivoPulizia && !ricorrenza || !isAttivoPulizia && ricorrenza) && (emailCliente))?
                  <button onClick={()=>aggiungi(false)}>Aggiungi</button> :
                  <button onClick={aggiungi} disabled style={{backgroundColor:'grey'}}>Aggiungi</button>
                  }
            </div>
            <div className='aggiungi-auto-da-csv'>
                <p>Aggiungi {option} da file excel</p>
                <button onClick={scaricaTemplateExcel}>Scarica Template</button>
                <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />
                {/*<button onClick={aggiungiAutoDaCsv}>Aggiungi</button>*/}
            </div> </>}
        </div>
    )
}

export default Aggiungi